@use "src/colors.scss";
@import '~bootstrap/scss/bootstrap';

.App {
  text-align: center;
}

.debug {
  position: absolute;
  color: gray;
  z-index: 10;
}

@media only screen and (orientation:landscape) {
  .game {
    display: block;
    width: 100vw;
    height: 100vh;
    position: relative;
  }
}

.rotate {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: var(--color-gray);
  color: white;
  font-size: 32px;
  font-weight: bold;
  padding-top: 100px;
}
