@use "../../colors.scss";

.infoWrapper {
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-gray);
    color: var(--color-yellow);
    .title {
        font-size: 42px;
        font-weight: bold;
    }
    .text {
        color: white;
        text-align: left;
        padding-left: 100px;
        padding-right: 100px;

    }
    button {
        margin-top: 20px;
        position: absolute;
        top: 0;
        right: 20px;
    }
}

