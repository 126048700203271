@use "../../colors.scss";

.gameWrapper {
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 5px;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-gray);
    color: #d9d9d9;

    .startDirections {
        padding: 20px;
        .subtitle {
            font-size: 12px;
            padding-top: 10px;
            letter-spacing: 1px;
        }
    }

    .startCountdown {
        padding: 20px;
        font-size: 64px;
        padding-top: 70px;
    }

    .gameOver {
        padding: 50px 0;
        .score {
            font-size: 55px;
            background-color: var(--color-yellow);
            color: var(--color-purple);
            padding: 20px 0;
        }
        button.btn-success {
            background-color: var(--color-green);
            border-color: var(--color-green);
        }
        button {
            margin: 20px;
        }
    }

    .game {
        padding-top: 20px;
        .gameMain {
            padding-top: 50px;
            padding-bottom: 20px;
            font-size: 64px;
            .success {
                background-color: var(--color-green);
            }
            .fail {
                background-color: var(--color-red);
            }
        }
        .gameCountdown {
            position: absolute;
            bottom: 100px;
            left: 50%;
            font-weight: normal;
            font-size: 24px;
        }
    }

    .debug {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 12px;
        font-weight: normal;
    }
}