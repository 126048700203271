@use "../../colors.scss";

.menuWrapper {
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-gray);
    color: var(--color-yellow);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .title {
        font-size: 50px;
        font-weight: bold;
        margin-top:-60px;
        .purple {
            color: var(--color-purple);
            background-color: var(--color-yellow);
            border-radius: 50px;
            padding: 0 12px;
            padding-bottom: 8px;
            font-style: italic;
        }
    }
    .buttons {
        margin-top: -60px;
        align-content: center;
        text-align: center;
        button.btn-success {
            margin-top: 20px;
            background-color: var(--color-green);
            border-color: var(--color-green);
            font-weight: bold;
        }
        button {
            display: block;
            margin: auto;
        }
        button.btn-secondary {
            margin-top: 20px;
        }
    }
    .ads {
        color: white;
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        bottom: 10px;
        left: 50px;
        width: 50vw
    }
}

